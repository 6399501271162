@font-face {
	font-family: "icons";
	src: url('../fonts/Icons/icons.eot');
	src: url('../fonts/Icons/icons.eot?#iefix') format('eot'),
	url('../fonts/Icons/icons.woff') format('woff'),
	url('../fonts/Icons/icons.ttf') format('truetype'),
	url('../fonts/Icons/icons.svg#icons') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="ico"]:before,
[class*=" ico"]:before,
[class^="ico"]:after,
[class*=" ico"]:after {
	display: inline-block;
	font-family: "icons";
	font-style: normal;
	font-weight: normal;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}


.ico-close:before { content: "\E001" }
@ico-close: "\E001";

.ico-list:before { content: "\E002" }
@ico-list: "\E002";

.ico-map:before { content: "\E003" }
@ico-map: "\E003";

.ico-target:before { content: "\E004" }
@ico-target: "\E004";