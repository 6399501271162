// Referencing for IDE
@import (reference) "@{bowerpath}/anchorLESS/anchor";
@import (reference) "_variables";

// Main setup
BODY, HTML { padding: 0; background: @color-white; height: 100vh; }
@media @media-mobile {
	BODY, HTML { background: @color-white; }
	.mobile-hide { display: none; }
}
BODY { font-size: @base-font-size; line-height: @base-line-height; font-family: @font-main; }

H2, H3, H4, H5, H6, P, UL, OL, TABLE { margin: @base-margin; }
H1, H2, H3, H4, H5, H6, TH { font-family: @font-heading; line-height: 1.4; font-weight: normal; }
H1 {
	font-size: 22px; margin: 0;
	+ H2 {
		margin: 0; color: @color01; font-size: ceil(@base-font-size * 1.5) !important;
		I { font-style: normal; font-weight: 300; }
	}
}
H2 { font-size: ceil(@base-font-size * 1.5); }
H3 { font-size: ceil(@base-font-size * 1.5); }
H1, H2, H3 {
	&[class*="ico-"]:before,
	&[class*=" ico-"]:before { display: inline-block; margin-right: 10px; vertical-align: 15%; font-size: 70%; background: @color01; color: @color-white; padding: .25em; .rounded(@base-radius); }
}
BUTTON {
	&:active, &:focus, &:hover { outline: none; border: none; }
}
@media @media-mobile {
	H1 { font-size: ceil(@base-font-size * 1.5); }
}

A { text-decoration: none; color: @color01; }

P {
	SUP { padding: 0 @gridunit*0.15; font-size: @base-font-size*0.6; font-weight: 600; }
	line-height: 1.4;
	&.large {
		font-size: @base-font-size*3.5 !important; font-weight: 300;
		SUP { font-size: @base-font-size*2; padding: 0 @gridunit*0.25; box-sizing: border-box; font-weight: 300; }
	}
}
SUP {
	@media @media-mobile { top: -0.6em !important; }
}

.supported {
	@media @media-tiny {
		display: none;
	}
	@media @media-desktop {
		height: 100%;
	}
}

.notsupported {
	@media @media-tiny {
		display: block;
		padding: @gridunit / 2;
		text-align: center;
		P.logo {
			margin: 0;
			IMG { width: 120px; height: auto; }
		}
	}
	@media only screen and (min-width: 240px) {
		display: none;
	}
}

// Main frame
.globalwrap {
	.flex-display(flex);
	@media @media-mobile { flex-flow: column; }
	.sidebar {
		.flex(0 0 @sidebar-width);
	}
	.globalview {
		.flex(1 1 auto);
	}
}

/* Google Maps */

// Map Base
#map {
	height: 100vh; width: 100%;
	// Height calc 100vh - @bar-height * 2
	@media @media-mobile { height: calc(~'100vh - 120px'); margin-top: @bar-height; }
}

.gm-style {
	font-size: @base-font-size !important;
}

// Hide Google rubbish
.gm-style-iw {
	display: flex;
	DIV & {
		display: flex;
	}
}

.gm-style-iw {
	order: -1;
	& + DIV { display: none !important; }
}
.tooltip_wrap {
	animation-name: fadeInDown;
	animation-fill-mode: forwards;
	animation-duration: 250ms;
	width: 200px !important;
}
.tooltip_outer {
	left: @gridunit !important;
	top: @gridunit*1.5 !important;
	@media @media-mobile { display: none !important; }
	> DIV {
		.rounded(10px); overflow: hidden; background: white; box-shadow: 0 0 18px 0 rgba(0,0,0,.2);
		-moz-background-clip: padding;
		-webkit-background-clip: padding-box;
		background-clip: padding-box;
	}
}
// Tooltips
.tooltip {
	overflow: hidden;
	> DIV {
		* { text-align: center; }
		A {
			&:hover {
				color: @color01 !important;
				SUP { color: @color01 !important; }
			}
		}
		padding: @gridunit; box-sizing: border-box;
		&.title { background: @color01; }
		&.content { padding-bottom: 0; }
	}
	H2 { font-size: @base-font-size !important; margin: 0; color: @color-white; font-weight: 600; }
	P { margin: @gridunit*0.25 0 0 0; font-size: @base-font-size; }
}

/* Sidebar */
.sidebar {
	overflow: hidden; background: @color-white; height: 100vh;
	@media @media-mobile {
		position: absolute; top: 0; left: 0; width: 100%; z-index: 1000; opacity: 0; pointer-events: none;
		&.active { height: auto; }
	}
	@media @media-notmobile { box-shadow: 0 0 25px 0 rgba(0,0,0,.15); z-index: 2; }
	&.active { pointer-events: all; opacity: 1; }
	H1 { text-align: center; background: @color01; color: @color-white; padding: @gridunit*1.25 0; }
	.pharmacies {
		list-style: none; margin: 0; padding: 0; height: 100%; width: 100%;
		@media @media-mobile { padding: @bar-height 0; box-sizing: border-box; }
		// Width calc @sidebar-width + scrollbar
		@media @media-notmobile { overflow-y: scroll; width: calc(@sidebar-width + 17px); }
		LI {
			display: flex; justify-content: space-between; align-items: center; padding: @gridunit; box-sizing: border-box; cursor: pointer; transition: background 250ms ease-in-out;
			* { margin: 0; }
			.logo {
				background: @color02;
				.flex-display(flex); justify-content: center; align-items: center; .flex(0 0 30px); height: 30px; border: 2px solid @color02; .rounded(@gridunit*0.25); pointer-events: none;
				IMG { width: 16px; height: 16px; }
				&.alt, &.sudameapteek, &.benu { background: none; }
				&.sudameapteek, &.benu {
					IMG { width: 18px; height: 18px; }
				}
				&.sudameapteek { border-color: @color-sudameapteek; }
				&.benu { border-color: @color-benu; }
			}
			H2 { .flex(1 1 auto); font-size: @base-font-size; font-weight: bold; padding: 0 @gridunit*0.75; box-sizing: border-box; pointer-events: none; }
			P {
				.flex(0 0 50px); font-size: @base-font-size*1.6; font-weight: 300; color: @color-gray-medium; line-height: 1; pointer-events: none; text-align: right;
				SUP { vertical-align: super; font-size: @base-font-size*0.85; font-weight: 300; top: 0; }
			}
			&:not(:first-child) {
				border-top: 1px solid @color-gray-light;
			}
			&.active {
				background: @color-gray-light;
				P { color: @color-black; }
			}
			&:hover {
				H2 { color: @color01; }
			}
		}
	}
}
.mobile-title, .list-toggle, .pharmacy-title, .pharmacy-content {
	display: none; text-align: center; position: fixed; left: 0; z-index: 2000; width: 100%; height: @bar-height; line-height: @bar-height;
	@media @media-mobile { display: block; }
}
.mobile-title, .pharmacy-title { font-weight: bold; background: @color01; color: @color-white; top: 0; }
.list-toggle, .pharmacy-content {
	&:after {
		content: ''; width: 100%; height: @gridunit*1.5; position: absolute; bottom: @bar-height; left: 0; box-shadow: inset 1px -19px 33px -25px rgba(0,0,0,0.75); pointer-events: none;
	}
	background: @color-white; border: none; color: @color01; bottom: 0;
}
.list-toggle {
	&.list {
		&:before { content: @ico-list; }
	}
	&.map {
		&:before { content: @ico-map; }
	}
	font-size: ceil(@base-font-size*1.45); outline: none; transition: all 250ms ease-in-out;
	&:before { padding-right: @gridunit*0.5; font-family: icons; }
	&:hover, &:focus, &:active { border: none; outline: none; background: @color01; color: @color-white; }
}
/* Pharmacy open */
BODY {
	&.pharmacy-open {
		.mobile-title, .list-toggle, .sidebar, .center_target { display: none; }
		.pharmacy { display: block; }
	}
}
.pharmacy {
	display: none;
	.pharmacy-title {
		height: auto; min-height: 60px; .flex-display(flex); justify-content: center; align-items: center; padding: @gridunit*0.5 @gridunit*3; box-sizing: border-box;
		H1 { font-weight: bold; }
		BUTTON {
			position: absolute; left: @gridunit; background: none; border: none;
			&:before { content: @ico-close; font-family: icons; line-height: @bar-height; height: @bar-height; }
		}
	}
	.pharmacy-content {
		* { color: @color-black; }
		height: auto; padding: @gridunit*2; box-sizing: border-box;
		&:after {
			top: -@gridunit*1.5;
		}
		P {
			margin: 0; font-size: @base-font-size*1.25;
			A {
				&:hover {
					color: @color01 !important;
					SUP { color: @color01 !important; }
				}
			}
		}
	}
}
/* Center map to current location */
BUTTON {
	&.center_target {
		box-sizing: border-box; position: fixed; top: @gridunit; right: @gridunit; z-index: 900; background: @color01; width: 45px; height: 45px; border: none; line-height: 45px; .rounded(10px); transition: all 250ms ease-in-out;
		&:before { content: @ico-target; font-family: icons; line-height: 45px; font-size: @base-font-size*1.65; color: @color-white; transition: all 250ms ease-in-out; }
		&:hover {
			background: @color-white;
			&:before { color: @color01; }
		}
		@media @media-mobile {
			top: @gridunit*4; width: 40px; height: 40px; line-height: 40px;
			&:before { line-height: 40px; font-size: @base-font-size*1.5; }
		}
	}
}