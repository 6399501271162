.gm-style {
  .gm-style-iw {
    top: 0 !important;
    left: 0 !important;
    width: 200px !important;
    > DIV {
      width: 200px;
    }
  }
}
.test {
  opacity: 0; pointer-events: none;
  overflow: hidden !important;
  &.active {
    animation-name: fadeInDown;
    animation-delay: .15s;
    animation-duration: 250ms;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
  }
}
.gm-style-iw {
  > DIV {

  }
}
// Disable scrollbar
.tooltip {
  background: @color-white;
}
