.clear {
	&:before,
	&:after {
		content: " "; // 1
		display: table; // 2
	}
	&:after {
		clear: both;
	}
	*zoom: 1;
}

.w50 { width: 50px; }
.w60 { width: 60px; }
.w70 { width: 70px; }
.w80 { width: 80px; }
.w90 { width: 90px; }
.w100 { width: 100px; }

.img-left { float: left; margin: .3em 1em 1em 0; clear: both; }
.img-right { float: right; margin: .3em 0 1em 1em; clear: both; }
.strike { text-decoration: line-through; }
.posrel { position: relative; }

.f-left { float: left; }
.f-right { float: right; }
.hidden { display: none; visibility: hidden; }

TR.hidden { visibility: visible; }
.ui-helper-hidden-accessible { display: none; }
.nowrap { white-space: nowrap; }
.left { text-align: left; }
.right { text-align: right; }
.center { text-align: center !important; }
.nomargin { margin: 0 !important; }
.bold,
.bold * { font-weight: bold !important; }

.w50p { width: 50%; }
.w100p { width: 100%; }

.w50 { width: 50px; }
.w60 { width: 60px; }
.w70 { width: 70px; }
.w80 { width: 80px; }
.w90 { width: 90px; }
.w100 { width: 100px; }

.mt10 { margin-top: 10px !important; }
.mt20 { margin-top: 20px !important; }
.mt30 { margin-top: 30px !important; }
.mt1em { margin-top: 1em !important; }
.mt2em { margin-top: 2em !important; }

.contrast (@prop, @val, @amount) when (lightness(@val) >= 50%) { @{prop}: darken(@val, @amount); }
.contrast (@prop, @val, @amount) when (lightness(@val) < 50%) { @{prop}: lighten(@val, @amount); }