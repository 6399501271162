// General grid units
@gridunit: 20px;
@base-margin: 20px 0 0 0;
@base-padding: 0 @gridunit @gridunit @gridunit;
@base-radius: 5px;

// Responsive sets
@media-tiny:   ~"only screen and (max-width: 239px)";
@media-notmobile:   ~"only screen and (min-width: 760px)";
@media-desktop:   ~"only screen and (min-width: 990px)";
@media-tablet:    ~"only screen and (min-width: 760px) and (max-width: 990px)";
@media-mobile:    ~"only screen and (max-width: 759px)";

// Colors
@color-main: #fff;
@color-text-main: #000;

@color01: #00a28b;
@color02: #ff2d1e;
@color-gray-light: #ededee;
@color-gray-medium: #8c939a;
@color-gray-dark: #4c535a;
@color-black: #000;
@color-white: #fff;

@color-benu: #64a925;
@color-sudameapteek: #ff4934;

// Typography
@font-main: "Lato", Arial, Helvetica, sans-serif;
@font-heading: "Lato", Arial, Helvetica, sans-serif;
@base-font-size: 14px;
@base-line-height: 17px;

// Frame
@sidebar-width: 300px;
@bar-height: 60px;
// Intents
.pt-intent-negative {
  color: @color02 !important;
  * { color: @color02 !important; }
}
.pt-intent-positive {
  color: @color01 !important;
  * { color: @color01 !important; }
}